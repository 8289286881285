// Imports
import Vue from 'vue';
import Router from 'vue-router';
import { trailingSlash } from '@/util/helpers';
import { layout, route } from '@/util/routes';

Vue.use(Router);

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    layout('Default', [
      route('Tenant List'),

      // Tables
      route('Report Tables', null, 'report'),
      route('Service Accounts', null, 'serviceAccounts'),
    ]),
    route('Login Page', null, '/login'),
  ],
});

export default router;
