import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins';
import store from './store';
import VueToast from 'vue-toast-notification';
import filters from './filters';
import { HalfCircleSpinner } from 'epic-spinners';

import 'vue-toast-notification/dist/theme-default.css';

// const host = window.location.host;
// const protocol = window.location.protocol;

Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));

Vue.component('HalfCircleSpinner', HalfCircleSpinner);

Vue.prototype.baseURL = `https://beaver.forfuns.net/`;
Vue.config.productionTip = false;
Vue.use(VueToast);
sync(store, router);

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
