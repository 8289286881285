<template>
  <div class="tables mt-2">
    <div class="container-fluid">
      <div class="col-12 m-3 text-center input-group">
        <div class="input-group" style="display:flex">
          <label class="mt-5">{{title}}：</label>
          <v-text-field
            class="col-2"
            id="startDate"
            v-model="startDate"
          ></v-text-field>
          <v-text-field
            v-if="showTime"
            class="col-2"
            id="startTime"
            v-model="startTime"
          ></v-text-field>
          <label class="mt-4">~</label>
          <v-text-field
            class="col-2"
            id="endDate"
            v-model="endDate"
          ></v-text-field>
          <v-text-field
            v-if="showTime"
            class="col-2"
            id="endTime"
            v-model="endTime"
          ></v-text-field>
          <div class="mt-3" v-if="showbtn">
            <v-btn color="blue-grey" class="mt-2 mr-1 white--text" small @click="today(true)">今天</v-btn>
            <v-btn color="blue-grey" class="mt-2 mr-1 white--text" small @click="today(false)">昨天</v-btn>
            <v-btn color="blue-grey" class="mt-2 mr-1 white--text" small @click="week(false)">本星期</v-btn>
            <v-btn color="blue-grey" class="mt-2 mr-1 white--text" small @click="week(true)">上星期</v-btn>
            <v-btn color="blue-grey" class="mt-2 mr-1 white--text" small @click="Month(false)">本月</v-btn>
            <v-btn color="blue-grey" class="mt-2 white--text" small @click="Month(true)">上月</v-btn>
          </div>
        </div>
        <v-btn
            color="primary"
            dark
            small
            class="col-12 mt-2 font-weight-bold text-h4"
            @click="doSearch()">
          搜寻
        </v-btn>
      </div>
      <div v-if="message" class="text-primary p-4">{{message}}</div>
    </div>
  </div>
</template>

<script>
import mixinDateAndTime from '@/assets/js/console';

export default {
  name: 'dateTime',
  props: ['title', 'showTime', 'showbtn'],
  components: {

  },
  data() {
    return {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      message: '',
    };
  },
  methods: {
    doSearch() {
      this.message = '';
      const date = {
        startDate: this.startDate,
        startTime: this.startTime,
        endDate: this.endDate,
        endTime: this.endTime,
      };
      this.$emit('search', date);
    },

    today(isToday) {
      let dt = new Date();
      dt.setDate(dt.getDate() + (isToday ? 0 : -1));
      let nowDate = dt.toJSON().slice(0, 10);
      dt.setDate(dt.getDate() + 1);
      let endDate = dt.toJSON().slice(0, 10);
      this.startDate = nowDate;
      this.endDate = endDate;
    },

    week(islast) {
      const dateTime = new Date().getTime();
      const dateDay = new Date().getDay();
      const timeZone = new Date().getTimezoneOffset();
      const oneDayTime = 24 * 60 * 60 * 1000;
      const week = islast ? 7 : 0;
      const proWeekList = [];
      let setdate = 0;
      for (let i = 0; i < 7; i += 1) {
        if (dateDay === 0) {
          setdate = dateTime - (dateDay - 1 + 7 + week - i) * oneDayTime;
        } else {
          setdate = dateTime - (dateDay - 1 + week - i) * oneDayTime;
        }
        const dt = new Date(setdate - (timeZone * 60000));
        proWeekList[i] = dt.toJSON().slice(0, 10);
      }
      const start = proWeekList[0];
      const end = proWeekList[6];
      this.startDate = start;
      this.endDate = end;
    },

    Month(islast) {
      const month = islast ? 1 : 0;
      const lastMonthDate = new Date();
      let setMonth = lastMonthDate.getMonth() - month;
      setMonth = setMonth < 0 ? 11 : setMonth;
      let nowYear = new Date().getYear();
      nowYear += (nowYear < 2000) ? 1900 : 0;
      nowYear -= setMonth === 11 ? 1 : 0;
      const dt = new Date(nowYear, setMonth, 1);
      const comMonth = dt.getMonth() < 10 ? `0${dt.getMonth() + 1}` : dt.getMonth() + 1;
      this.startDate = `${dt.getFullYear()}-${comMonth}-01`;
      const end = new Date(nowYear, setMonth + 1, 0);
      const comMonthEnd = end.getMonth() + 1 < 10 ? `0${end.getMonth() + 1}` : end.getMonth() + 1;
      this.endDate = `${end.getFullYear()}-${comMonthEnd}-${end.getDate()}`;
    },
  },
  mixins: [mixinDateAndTime],
  mounted() {
    this.today(true);
    this.doSearch();
  },
};
</script>
<style>
.table-th {
  background: #d8eef7;
  color: #4b4670;
  font-weight: 900;
}
</style>
