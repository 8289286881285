/* eslint-disable */
import laydate from './laydate/laydate';
import './laydate.css';

export default {
  computed: {
    fullStartDateTime() {
      return `${this.startDate} ${this.startTime}`;
    },
    fullEndDateTime() {
      return `${this.endDate} ${this.endTime}`;
    },
  },
  mounted() {
    this.showDateAndTime();
  },
  methods: {
    checkDateAndTime() {
      this.startDate = document.getElementById('startDate').value;
      this.startTime = document.getElementById('startTime').value;
      this.endDate = document.getElementById('endDate').value;
      this.endTime = document.getElementById('endTime').value;
      if (this.startDate === '' || this.startTime === '' || this.endDate === '' || this.endTime === '') {
        this.message = '结束时间不得比开始时间早';
        return false;
      }
      if (this.fullStartDateTime > this.fullEndDateTime) {
        this.message = '结束时间不得比开始时间早';
        return false;
      }
      return true;
    },
    checkDateAndTimeRangeAWeek() {
      if (this.checkDateAndTimeRange(7 * 24)) {
        return true;
      }
      this.message = '最大为一周';
      return false;
    },
    checkDateAndTimeRange(hours) {
      if (!hours) {
        return true;
      }
      const diffMS = parseInt(new Date(this.fullEndDateTime)
      - new Date(this.fullStartDateTime), 10);
      if (diffMS > (hours * 60 * 60 * 1000)) {
        return false;
      }
      return true;
    },
    showDateAndTime() {
      const dt = new Date();
      const nowDate = dt.toJSON().slice(0, 10);
      const nowTime = `${dt.getHours()}${dt.toJSON().slice(13, 19)}`;

      dt.setDate(dt.getDate() - 1);
      const yesterday = dt.toJSON().slice(0, 10);

      this.startDate = yesterday;
      this.startTime = nowTime;
      this.endDate = nowDate;
      this.endTime = nowTime;

      laydate.render({
        elem: '#startDate',
        theme: '#37383a',
        lang: 'cn',
        done: (value) => {
          this.startDate = value;
        },
      });
      laydate.render({
        elem: '#startTime',
        type: 'time',
        theme: '#37383a',
        lang: 'cn',
        done: (value) => {
          this.startTime = value;
        },
      });
      laydate.render({
        elem: '#endDate',
        theme: '#37383a',
        lang: 'cn',
        done: (value) => {
          this.endDate = value;
        },
      });
      laydate.render({
        elem: '#endTime',
        type: 'time',
        theme: '#37383a',
        lang: 'cn',
        done: (value) => {
          this.endTime = value;
        },
      });
    },
  },
};
