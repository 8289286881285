// Pathify
import { make } from 'vuex-pathify';

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  items: [
    {
      title: '站台列表',
      icon: 'mdi-account-group',
      to: '/',
    },
    {
      title: '报表',
      icon: 'mdi-clipboard-outline',
      to: '/report',
    },
    {
      title: '信任服务',
      icon: 'mdi-clipboard-outline',
      to: '/serviceAccounts',
    },
  ],
};

const mutations = make.mutations(state);

const actions = {
  ...make.actions(state),
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
