const TransactionTypeFilter = ['Credit', 'ServiceFee'];

function padNumber(number, digits) {
  let result = number.toString();
  while (result.length < digits) {
    result = `0${result}`;
  }
  return result;
}

const formatDts = (timestamp) => {
  if (timestamp === undefined || timestamp <= 0) {
    return '';
  }
  const d = new Date(timestamp);
  return `${d.getFullYear()}-${padNumber(d.getMonth() + 1, 2)}-${padNumber(d.getDate(), 2)} ${padNumber(d.getHours(), 2)}:${padNumber(d.getMinutes(), 2)}:${padNumber(d.getSeconds(), 2)}`;
};

const transactioType = (type) => {
  return TransactionTypeFilter[type];
};

export default {
  formatDts,
  transactioType,
};
